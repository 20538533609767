import React from "react";
import logo_org from '@img/AppImg/logo_org.png';
import channel from './app_channel_features';
let conf={
    apiInstance: {
        hosts: {
            'dsm.huimanfen.com': 'prod',
            'tutor.huimanfen.com': 'prod',
            't.huimanfen.com': 'prod',
            'org.huimanfen.com': 'prod',
            'partner.huimanfen.com': 'prod',
            'dsm.dasih.vip': 'prod',
            'dsm.lianxue.com': 'prod',
            'test.dsm.lianxue.com': 'test',
            'dev.dsm.lianxue.com': 'dev',
            'd79app.huimanfen.com': 'dev',
            'localhost': 'dev'
        },
        app:{
            'tutor':{
                appKey:'tutor', permission:'dashi_client_login_tutor',
                logoText:<span className={'logo-text'}><label className={'logo-label1'}>AI教学系统-教师端</label><label className={'logo-label2'}></label></span>
            },
            't':{
                appKey:'tutor', permission:'dashi_client_login_tutor',
                logoText:<span className={'logo-text'}><label className={'logo-label1'}>AI教学系统-教师端</label><label className={'logo-label2'}></label></span>
            },
            'dsm':{
                appKey:'dsm', permission:'dashi_client_login_dsm',
                logoText:<span className={'logo-text'}><label className="logo-label1">后台管理系统</label><label className="logo-label2"></label></span>
            },
            'partner':{
                appKey:'dealer', permission:'dashi_client_login_dealer',
                logoText:<span className={'logo-text'}><label className="logo-label1">城市合伙人</label><label className="logo-label2"></label></span>
            },
            'org':{
                appKey:'org', permission:'dashi_client_login_hmforg',
                logoImg:logo_org,
                logoText:<span className={'logo-text'}><label className="logo-label1">AI智慧教育平台</label><label className="logo-label2"></label></span>
            },
        },
        channel:channel,
        env:{
            dev:{
                apiHost:'https://d79app.huimanfen.com',
                wsHost:'wss://dev-ws.huimanfen.com',
                wsHttpHost:'https://dev-ws.huimanfen.com',
                // wsChatHost:'wss://dev-chat.huimanfen.com',
                apiChatHost:'https://dev-chat.huimanfen.com',
                // apiChatHost:'http://localhost:3090',
                wsChatHost:'ws://localhost:3090',
                // wsHttpHost:'http://localhost:3080',
                // wsHost:'ws://localhost:3080',
            },
            prod:{
                apiHost:'https://app.lianxue.com',
                wsHost:'wss://ws.huimanfen.com',
                wsHttpHost:'https://ws.huimanfen.com',
                wsChatHost:'wss://chat.huimanfen.com',
                apiChatHost:'https://chat.huimanfen.com',
            },
        },
        // header:{
        //     realm:{key:'X-LX-Realm',value:'dashi'}
        // },
        api:{
            tts:{
                tts:{
                    getUrl:{base:'https://di.huimanfen.com',method:'get',url:'/api/tts/url'}
                },
            },
            auth:{
                login:{method:'post',url:'/api/passport/v1/token'},
                regist: {method:'post',url:'/api/passport/v1/user/other'},
                update: {method:'put',url:'/api/passport/v1/user/{id}'},
                register: {method:'post',url:'/api/passport/v1/user'},
                getUserBaseInfo:{method:'get',url:'/api/passport/v1/me'},
                findUserInfoByIds:{method:'get',url:'/api/passport/v1/user/find/contacts'},
                paging: {method:'get',url:'/api/passport/v1/user/find'},
                resetUserPwd:{method:'put',url:'/api/passport/v1/user/{id}/password'},
                ban: {method:'post',url:'api/passport/v1/user/{userId}/ban'},
                import: {method:'post',url:'api/passport/v1/import'},
            },
            idaddy:{
                auth:{
                    token:{method:'get',url:'/api/foundation/v1/idaddy/token'}
                },
                category:{
                    find:{method:'post',url:'/api/foundation/v1/idaddy/catalog'}
                },
                work:{
                    query:{method:'post',url:'/api/foundation/v1/idaddy/query'},
                    list:{method:'post',url:'/api/foundation/v1/idaddy/articles'},
                    info:{method:'post',url:'/api/foundation/v1/idaddy/article'}
                }
            },
            audioroom:{
                activity:{
                    activity:{
                        create:{method:'post',url:'/api/audioroom/activity/activity/create'},
                        update:{method:'post',url:'/api/audioroom/activity/activity/update'},
                        delete:{method:'post',url:'/api/audioroom/activity/activity/delete'},
                        findByIds:{method:'post',url:'/api/audioroom/activity/activity/findByIds'},
                        findByPaging:{method:'post',url:'/api/audioroom/activity/activity/paging'},
                        createPermission:{method:'post',url:'/api/audioroom/activity/activity/createGiftPermission'}
                    },
                    statistics:{
                        findLevelSort:{method:'post',url:'/api/audioroom/activity/statistics/findLevelSort'}
                    }
                },
                gift:{
                    product:{
                        create:{method:'post',url:'/api/audioroom/gift/product/create'},
                        update:{method:'post',url:'/api/audioroom/gift/product/update'},
                        delete:{method:'post',url:'/api/audioroom/gift/product/delete'},
                        findByIds:{method:'post',url:'/api/audioroom/gift/product/findByIds'},
                        findByPaging:{method:'post',url:'/api/audioroom/gift/product/findByPaging'},
                    },
                    permission:{
                        create:{method:'post',url:'/api/audioroom/gift/permission/create'},
                        findByPaging:{method:'post',url:'/api/audioroom/gift/permission/findByPaging'},
                    },
                    shelves:{
                        create:{method:'post',url:'/api/audioroom/gift/shelves/create'},
                        update:{method:'post',url:'/api/audioroom/gift/shelves/update'},
                        delete:{method:'post',url:'/api/audioroom/gift/shelves/delete'},
                        findByIds:{method:'post',url:'/api/audioroom/gift/shelves/findByIds'},
                        findByPaging:{method:'post',url:'/api/audioroom/gift/shelves/findByPaging'},
                    },
                    shelvesItem:{
                        create:{method:'post',url:'/api/audioroom/gift/shelvesItem/create'},
                        update:{method:'post',url:'/api/audioroom/gift/shelvesItem/update'},
                        delete:{method:'post',url:'/api/audioroom/gift/shelvesItem/delete'},
                        findByIds:{method:'post',url:'/api/audioroom/gift/shelvesItem/findByIds'},
                        findAndSortByIds:{method:'post',url:'/api/audioroom/gift/shelvesItem/findAndSortByIds'},
                        findByShelvesId:{method:'post',url:'/api/audioroom/gift/shelvesItem/findByShelvesId'},
                    },
                    exchange:{
                        delete:{method:'post',url:'/api/audioroom/gift/exchange/delete'},
                        deleteAndReturnPoints:{method:'post',url:'/api/audioroom/gift/exchange/deleteAndReturnPoints'},
                        findByPaging:{method:'post',url:'/api/audioroom/gift/exchange/findByPaging'},
                    }
                },
                reciting:{
                    analyse:{
                        findWeekUserList:{method:'post',url:'/api/audioroom/reciting/analyse/findWeekUserList'}
                    }
                },
                setting:{
                    schoolContext:{
                        modify:{method:'post',url:'/api/audioroom/setting/schoolContext/modify'},
                        enable:{method:'post',url:'/api/audioroom/setting/schoolContext/enable'},
                        findOne:{method:'post',url:'/api/audioroom/setting/schoolContext/findOne'},
                    },
                    business: {
                        create:{method:'post',url:'/api/audioroom/setting/business/create'},
                        update:{method:'post',url:'/api/audioroom/setting/business/update'},
                        delete:{method:'post',url:'/api/audioroom/setting/business/delete'},
                        findByIds:{method:'post',url:'/api/audioroom/setting/business/findByIds'},
                        paging:{method:'post',url:'/api/audioroom/setting/business/paging'},
                    }
                },
                castle:{
                    distribution:{
                        modify:{method:'post',url:'/api/audioroom/castle/distribution/modify'},
                        findBySchool:{method:'post',url:'/api/audioroom/castle/distribution/findBySchool'},
                    },
                },
                form:{
                    form:{
                        paging:{method:'post',url:'/api/audioroom/form/form/paging'},
                        update:{method:'post',url:'/api/audioroom/form/form/update'},
                        delete:{method:'post',url:'/api/audioroom/form/form/delete'},
                    },
                },
                point:{
                    point:{
                        findByUser:{method:'post',url:'/api/audioroom/point/point/findByUser'},
                        findByTop:{method:'post',url:'/api/audioroom/point/point/findByTop'},
                    },
                }
            },
            payment:{
                product:{
                    paging:{method:'get',url:'/api/pay/v1/product'},
                    create:{method:'post',url:'/api/pay/v1/product'},
                    update:{method:'put',url:'/api/pay/v1/product/{productId}'},
                    delete:{method:'delete',url:'/api/pay/v1/product/{productId}'}
                },
                prime:{
                    set:{method:'post',url:'/api/pay/v1/user/{userId}/prime'},
                    del:{method:'delete',url:'/api/pay/v1/user/{userId}/prime'},
                    getByUserIds:{method:'get',url:'/api/pay/v1/user/primes'},
                    log:{method:'get',url:'/api/pay/v1/prime-log'},
                }
            },
            ticket:{
                ticket:{
                    paging:{method:'get',url:'/api/ticket/v1/ticket'},
                    create:{method:'post',url:'/api/ticket/v1/ticket'},
                    delete:{method:'delete',url:'/api/ticket/v1/ticket/{ticketId}'},
                    hold:{method:'post',url:'/api/ticket/v1/ticket/{ticketId}/hold'},
                    halt:{method:'post',url:'/api/ticket/v1/ticket/{ticketId}/halt'},
                    revoke:{method:'post',url:'/api/ticket/v1/ticket/{ticketId}/revoke'},
                    active:{method:'post',url:'/api/ticket/v1/ticket/{ticketId}/active'},
                    redeliver:{method:'post',url:'/api/castle/v1/homework/redeliver'}
                },
                receipt:{
                    paging:{method:'get',url:'/api/ticket/v1/receipt'}
                },
                template:{
                    paging:{method:'get',url:'/api/ticket/v1/template'},
                    create:{method:'post',url:'/api/ticket/v1/template'},
                    update:{method:'post',url:'/api/ticket/v1/template/{templateId}'},
                    delete:{method:'delete',url:'/api/ticket/v1/template/{templateId}'},
                },
            },
            acl:{
                permission:{
                    all:{method:'get',url:'/api/acl/v1/permission'},
                    my:{method:'get',url:'/api/acl/v1/my/permissions'},
                    create:{method:'put',url:'/api/acl/v1/permission/{code}'},
                    update:{method:'put',url:'/api/acl/v1/permission/{code}'},
                    delete:{method:'delete',url:'/api/acl/v1/permission/{code}'}
                },
                role:{
                    paging:{method:'get',url:'/api/acl/v1/role-setting'},
                    create:{method:'put',url:'/api/acl/v1/role-setting/{code}'},
                    update:{method:'put',url:'/api/acl/v1/role-setting/{code}'},
                    delete:{method:'delete',url:'/api/acl/v1/role-setting/{code}'}
                }
            },
            sale:{
                transit:{
                    search:{method:'get',url:'/api/sale/v1/transit'},
                    searchX:{method:'post',url:'/api/sale/v1/transit/search'},
                    detail:{method:'get',url:'/api/sale/v1/transit/{id}'},
                    update:{method:'put',url:'/api/sale/v1/transit/{id}'},
                    create:{method:'post',url:'/api/sale/v1/transit'},
                },
                group:{
                    list:{method:'get',url:'/api/sale/v1/group'},
                    detail:{method:'get',url:'/api/sale/v1/group/{groupId}'},
                    add:{method:'post',url:'/api/sale/v1/group'},
                    update:{method:'put',url:'/api/sale/v1/group/{groupId}'},
                    addActors:{method:'post',url:'/api/sale/v1/group/{groupId}/join-robot-user'}
                },
                distributor:{
                    delete:  {method:'delete',url:'/api/sale/v1/distributor/{distributorId}'},
                    update:  {method:'put',   url:'/api/sale/v1/distributor/{distributorId}'},
                    create:  {method:'post',  url:'/api/sale/v1/distributor'},
                    paging:  {method:'get',   url:'/api/sale/v1/distributor'},
                    preset:  {method:'post',  url:'/api/sale/v1/admin/distributor/{distributor}/appointment-customer'},
                    userInfo:{method:'get',   url:'/api/sale/v1/admin/distributor/{distributorId}'},
                    getDistributor: {method:'get',url:'/api/sale/v1/admin/distributor'},
                    addDistributor: {method:'post',url:'/api/sale/v1/admin/distributor'},
                    editDistributor: {method:'put',url:'/api/sale/v1/admin/distributor/{distributor}'},
                    findDistributor: {method:'get',url:'/api/sale/v1/admin/distributor/{distributor}'},
                    delDistributor:  {method:'delete',url:'/api/sale/v1/admin/distributor/{distributor}'},
                    markDistributor: {method:'post',url:'/api/sale/v1/admin/mark-cash-out/{distributor}'},
                    inherit:    {method:'get',  url: '/api/sale/v1/admin/distributor/{distributor}/enable-commision-inherit'},
                    unInherit:  {method:'get',  url: '/api/sale/v1/admin/distributor/{distributor}/disable-commision-inherit'},
                    reports:    {method:'get',url:'/api/sale/v1/admin/distributor-dealer-report/lists'},
                },
                my: {
                    distributor: {method:'get',url:'/api/sale/v1/my/distributor', desc:'直属下游列表'},
                    customers: {method:'get',url:'/api/sale/v1/my/customers/{distributor}', desc:'直属客户列表'},
                    settlements: {method:'get',url:'/api/sale/v1/my/settlements/{distributor}', desc:'我的结算列表'},
                },
                admin:  {
                    customers: {method:'get',url:'/api/sale/v1/admin/customers'},
                    logs: {method:'get',url:'/api/sale/v1/admin/share-logs'},
                    settlements: {method:'get',url:'/api/sale/v1/admin/share-settlements'},
                    cashs: {method:'get',url:'/api/sale/v1/admin/share-cashs'},
                    dealer:{
                        create: {method:'post',  url: '/api/sale/v1/admin/dealer'},
                        edit:   {method:'put',  url: '/api/sale/v1/admin/dealer/{dealer}'},
                        list:   {method:'get',  url: '/api/sale/v1/admin/dealer'},
                        del:    {method:'delete',  url: '/api/sale/v1/admin/dealer/{dealer}'},
                        report: {method:'get',  url: '/api/sale/v1/admin/dealer-report/lists'},
                    },
                    gcard: {    
                        activeToOther:  {method:'get',  url: '/api/sale/v1/gcard/apply-other/code/{code}'},
                        categorys:  {method:'get',  url: '/api/sale/v1/admin/gcard/config/lists'},
                        printed:    {method:'put',  url: '/api/sale/v1/admin/gcard/printed'},
                        create:     {method:'post', url: '/api/sale/v1/admin/gcard/generate'},
                        batchs:     {method:'get',  url: '/api/sale/v1/admin/gcard/batch/lists'},
                        del:        {method:'put',  url: '/api/sale/v1/admin/gcard/discard'},
                        list:       {method:'get',  url: '/api/sale/v1/admin/gcard/lists'},
                        bySmart:    {method:'post', url: '/api/sale/v1/admin/gcard/dispatch/by-smart'},
                        byBatch:    {method:'post', url: '/api/sale/v1/admin/gcard/dispatch/by-batch'},
                        export:     {method:'post', url: '/api/sale/v1/admin/gcard/preset-export'},
                        downExcel:  {method:'getDown', url: '/api/sale/v1/admin/gcard/export/{key}'},
                    }
                },
                partner: {
                    dealers:        {method:'get',  url: '/api/sale/v1/partner/my/dealer'},
                    distributors:   {method:'get',  url: '/api/sale/v1/partner/distributor/lists'},
                    customers:      {method:'get',  url: '/api/sale/v1/partner/distributor/customer-lists'},
                    reports:        {method:'get',  url: '/api/sale/v1/partner/distributor-dealer-report/lists'},
                }
            },
            devlog:{
                order:{
                    day:{
                        search:{method:'post',  url: '/api/devlog/order/day/search'},
                    }
                },
                statistics:{
                    day:{
                        search:{method:'post',  url: '/api/devlog/statistics/day/search'},
                        searchUser:{method:'post',  url: '/api/devlog/statistics/day/searchUser'},
                        searchArea:{method:'post',  url: '/api/devlog/statistics/day/searchArea'},
                    },
                    week:{
                        search:{method:'post',  url: '/api/devlog/statistics/week/search'},
                        searchUser:{method:'post',  url: '/api/devlog/statistics/week/searchUser'},
                        searchArea:{method:'post',  url: '/api/devlog/statistics/week/searchArea'},
                    },
                    month:{
                        search:{method:'post',  url: '/api/devlog/statistics/month/search'},
                        searchUser:{method:'post',  url: '/api/devlog/statistics/month/searchUser'},
                        searchArea:{method:'post',  url: '/api/devlog/statistics/month/searchArea'},
                    }
                }
            },
            analyse:{
                timer:{
                    task:{
                        paging:{method:'post',url:'/api/analyse/timer/task/paging'},
                        create:{method:'post',url:'/api/analyse/timer/task/create'},
                        update:{method:'post',url:'/api/analyse/timer/task/update'},
                        enable:{method:'post',url:'/api/analyse/timer/task/enable'},
                        delete:{method:'post',url:'/api/analyse/timer/task/delete'},
                        run:{method:'post',url:'/api/analyse/timer/task/run'},
                    },
                    record:{
                        findByTaskId:{method:'post',url:'/api/analyse/timer/record/findByTaskId'},
                    }
                },
                version:{
                    task:{
                        paging:{method:'post',url:'/api/analyse/version/task/paging'},
                        create:{method:'post',url:'/api/analyse/version/task/create'},
                        update:{method:'post',url:'/api/analyse/version/task/update'},
                        enable:{method:'post',url:'/api/analyse/version/task/enable'},
                        delete:{method:'post',url:'/api/analyse/version/task/delete'},
                    },
                    upgrade:{
                        paging:{method:'post',url:'/api/analyse/version/upgrade/paging'},
                    }
                },
                favorite:{
                    find:{method:'post',url:'/api/analyse/favorite/find'},
                    create:{method:'post',url:'/api/analyse/favorite/create'},
                    delete:{method:'post',url:'/api/analyse/favorite/delete'},
                },
                log:{
                    processDayReport:{method:'post',url:'/api/devlog/log/processDayReport'},
                    paging:{method:'post',url:'/api/devlog/log/paging'},
                    list:{method:'post',url:'/api/devlog/log/list'},
                    count:{method:'post',url:'/api/devlog/log/count'},
                    delete:{method:'post',url:'/api/devlog/log/delete'},
                },
                pk:{
                    room:{
                        findByIds:{method:'post',url:'/api/analyse/pk/room/findByIds'},
                        paging:{method:'post',url:'/api/analyse/pk/room/paging'},
                        create:{method:'post',url:'/api/analyse/pk/room/create'},
                        changeStatus:{method:'post',url:'/api/analyse/pk/room/changeStatus'},
                        delete:{method:'post',url:'/api/analyse/pk/room/delete'},
                    },
                    result:{
                        findByRoomId:{method:'post',url:'/api/analyse/pk/result/findByRoomId'},
                    },
                    report:{
                        modify:{method:'post',url:'/api/analyse/pk/report/modify'},
                        findByRoomIds:{method:'post',url:'/api/analyse/pk/report/findByRoomIds'},
                    },
                },
                file:{
                    findByIds:{method:'get',url:'/api/analyse/file/findByIds'},
                    paging:{method:'post',url:'/api/analyse/file/paging'},
                    list:{method:'post',url:'/api/analyse/file/list'},
                    tree:{method:'get',url:'/api/analyse/file/tree'},
                    delete:{method:'post',url:'/api/analyse/file/delete'},
                    update:{method:'post',url:'/api/analyse/file/update'},
                    create:{method:'post',url:'/api/analyse/file/create'},
                    refreshPreviews:{method:'post',url:'/api/analyse/file/refreshPreviews'}
                },
                hudong:{
                    roomReport:{
                        modify:{method:'post',url:'/api/analyse/hudong/roomReport/modify'}
                    },
                    roomAnalyse:{
                        create:{method:'post',url:'/api/analyse/hudong/roomAnalyse/create'},
                        reportModify:{method:'post',url:'/api/analyse/hudong/roomAnalyse/reportModify'}
                    },
                    planTemplate:{
                        findByIds:{method:'post',url:'/api/analyse/hudong/hudongTemplate/findByIds'},
                        sortByIds:{method:'post',url:'/api/analyse/hudong/hudongTemplate/sortByIds'},
                        tree:{method:'post',url:'/api/analyse/hudong/hudongTemplate/tree'},
                        list:{method:'post',url:'/api/analyse/hudong/hudongTemplate/list'},
                        childTree:{method:'post',url:'/api/analyse/hudong/hudongTemplate/childTree'},
                        childList:{method:'post',url:'/api/analyse/hudong/hudongTemplate/childList'},
                        create:{method:'post',url:'/api/analyse/hudong/hudongTemplate/create'},
                        update:{method:'post',url:'/api/analyse/hudong/hudongTemplate/update'},
                        delete:{method:'post',url:'/api/analyse/hudong/hudongTemplate/delete'},
                    },
                    hudongPaper:{
                        findByIds:{method:'post',url:'/api/analyse/hudong/hudongPaper/findByIds'},
                        sortByIds:{method:'post',url:'/api/analyse/hudong/hudongPaper/sortByIds'},
                        tree:{method:'post',url:'/api/analyse/hudong/hudongPaper/tree'},
                        list:{method:'post',url:'/api/analyse/hudong/hudongPaper/list'},
                        childTree:{method:'post',url:'/api/analyse/hudong/hudongPaper/childTree'},
                        childList:{method:'post',url:'/api/analyse/hudong/hudongPaper/childList'},
                        create:{method:'post',url:'/api/analyse/hudong/hudongPaper/create'},
                        update:{method:'post',url:'/api/analyse/hudong/hudongPaper/update'},
                        delete:{method:'post',url:'/api/analyse/hudong/hudongPaper/delete'},
                        deleteQItem:{method:'post',url:'/api/analyse/hudong/hudongPaper/deleteQItem'},
                        modifyQItem:{method:'post',url:'/api/analyse/hudong/hudongPaper/modifyQItem'},
                    }
                },
                courseware:{
                    courseware:{
                        findByIds:{method:'post',url:'/api/analyse/courseware/courseware/findByIds'},
                        sortByIds:{method:'post',url:'/api/analyse/courseware/courseware/sortByIds'},
                        tree:{method:'post',url:'/api/analyse/courseware/courseware/tree'},
                        list:{method:'post',url:'/api/analyse/courseware/courseware/list'},
                        childTree:{method:'post',url:'/api/analyse/courseware/courseware/childTree'},
                        childList:{method:'post',url:'/api/analyse/courseware/courseware/childList'},
                        create:{method:'post',url:'/api/analyse/courseware/courseware/create'},
                        update:{method:'post',url:'/api/analyse/courseware/courseware/update'},
                        delete:{method:'post',url:'/api/analyse/courseware/courseware/delete'},
                    }
                },
                custom:{
                    read:{
                        findByIds:{method:'post',url:'/api/analyse/custom/read/findByIds'},
                        sortByIds:{method:'post',url:'/api/analyse/custom/read/sortByIds'},
                        childList:{method:'post',url:'/api/analyse/custom/read/childList'},
                        tree:{method:'post',url:'/api/analyse/custom/read/tree'},
                        list:{method:'post',url:'/api/analyse/custom/read/list'},
                        create:{method:'post',url:'/api/analyse/custom/read/create'},
                        update:{method:'post',url:'/api/analyse/custom/read/update'},
                        updateTextAudio:{method:'post',url:'/api/analyse/custom/read/updateTextAudio'},
                        delete:{method:'post',url:'/api/analyse/custom/read/delete'}
                    }
                }
            },
            voice:{
                analyse:{
                    findByRecordIds:{method:'post',url:'/api/voice/analyse/findByRecordIds'},
                    process:{method:'post',url:'/api/voice/analyse/processWS'},
                },
                cos:{
                    webUrlToCos:{method:'post',url:'/api/voice/cos/webUrlToCos'}
                }
            },
            castle:{
                asset:{
                    create:{method:'post',url:'/api/castle/v1/asset'},
                    detail:{method:'get',url:'/api/castle/v1/asset/{id}'},
                    update:{method:'put',url:'/api/castle/v1/asset/{id}'},
                    delete:{method:'delete',url:'/api/castle/v1/asset/{id}'},
                    find:{method:'get',url:'/api/castle/v1/asset'},
                },
                varrant:{
                    paging:{method:'get',url:'/api/castle/v1/warrant'},
                    create:{method:'post',url:'/api/castle/v1/warrant'},
                    update:{method:'put',url:'/api/castle/v1/warrant/{id}'},
                    delete:{method:'delete',url:'/api/castle/v1/warrant/{id}'},
                    setActived:{method:'post',url:'/api/castle/v1/warrant/{id}/set-actived'},
                    group:{
                        list:{method:'get',url:'/api/castle/v1/warrantGroup'}
                    },
                    userGrant:{
                        add:{method:'post',url:'/api/castle/v1/warrant/catalog/grant'},
                        list:{method:'get',url:'/api/student/v1/admin/inventory/lists'},
                        remove:{method:'post',url:'/api/castle/v1/warrant/catalog/remove'},
                    }
                },
                org:{
                    dashboard:{method:'get',url:'/api/castle/v1/org/{orgId}/dashboard'},
                    charge:{
                        set:{method:'post',url:'/api/castle/v1/org/{orgId}/charge'},
                        list:{method:'get',url:'/api/castle/v1/org/{orgId}/charge-logs'},
                    },
                    list:{method:'get',url:'/api/castle/v1/org'},
                    detail:{method:'get',url:'/api/castle/v1/org/{orgId}'},
                    create:{method:'post',url:'/api/castle/v1/org'},
                    remove:{method:'delete',url:'/api/castle/v1/org/{orgId}'},
                    update:{method:'put',url:'/api/castle/v1/org/{orgId}'},
                    my:{method:'get',url:'/api/castle/v1/my/orgs'},
                    getByShorId:{method:'get',url:'/api/castle/v1/org/short/{shortId}'},
                    man:{
                        join:{method:'post',url:'/api/castle/v1/man'},
                        update:{method:'put',url:'/api/castle/v1/man/{manId}'},
                        info:{method:'get',url:'/api/castle/v1/man/{manId}'},
                        remove:{method:'delete',url:'/api/castle/v1/man/{manId}'},
                        list:{method:'get',url:'/api/castle/v1/man'},
                    },
                    gcard:{
                        list:{method:'get',url:'/api/castle/v1/org/{orgId}/gcard'},
                        status:{method:'get',url:'/api/castle/v1/org/{orgId}/gcard/states'},
                        apply:{method:'get',url:'/api/castle/v1/org/{orgId}/gcard/apply'},

                    },
                    study:{
                        status:{method:'get',url:'/api/castle/v1/org/{orgId}/course/{courseId}/states'},
                        report:{method:'get',url:'/api/castle/v1/org/{orgId}/user/{userId}/report/lesson/{lessonId}'},
                        unlock:{method:'put',url:'/api/castle/v1/org/{orgId}/course/{courseId}'}
                    }
                },
                school:{
                    paging:{method:'get',url:'/api/castle/v1/school'},
                    create:{method:'post',url:'/api/castle/v1/school'},
                    detail:{method:'get',url:'/api/castle/v1/school/{schoolId}'},
                    update:{method:'put',url:'/api/castle/v1/school/{schoolId}'},
                    member:{
                        move:{method:'post',url:'/api/castle/v1/castle/move-member'}
                    },
                    user:{
                        check:{method:'get',url:'/api/castle/v1/school/user/check'},
                        listBySchoolId:{method:'get',url:'/api/castle/v1/school/user/by-school/{schoolId}'},
                        getUserSchool:{method:'get',url:'/api/castle/v1/school/user/by-user-ids'},
                        update:{method:'put',url:'/api/castle/v1/school/user/{userId}'},
                        create:{method:'post',url:'/api/castle/v1/school/{schoolId}/user'},
                        delete:{method:'delete',url:'/api/castle/v1/school/user/{userId}'},
                    }
                },
                province:{
                    paging:{method:'get',url:'/api/foundation/v1/conf/province'},
                },
                classes:{
                    find:{method:'get',url:'/api/castle/v1/castle/find',useCache:1},
                    paging:{method:'get',url:'/api/castle/v1/castle'},
                    myManagement:{method:'get',url:'/api/castle/v1/castle/linked'},
                    get:{method:'get',url:'/api/castle/v1/castle/{castleId}'},
                    create:{method:'post',url:'/api/castle/v1/castle'},
                    update:{method:'put',url:'/api/castle/v1/castle/{castleId}'},
                    updateAdmin:{method:'post',url:'/api/castle/v1/castle/{castleId}/update-admin'},
                    import:{method:'post',url:'/api/castle/v1/castle/import'},
                    archive:{method:'post',url:'/api/castle/v1/castle/{castleId}/archive'},
                },
                member:{
                    paging:{method:'get',url:'/api/castle/v1/castle/{castleId}/member'},
                    join:{method:'post',url:'/api/castle/v1/castle/{castleId}/member'},
                    leave:{method:'delete',url:'/api/castle/v1/castle/{castleId}/member/{memberId}'},
                    update:{method:'post',url:'/api/castle/v1/castle/{castleId}/member'},
                },
                study:{
                    status:{method:'get',url:'/api/castle/v1/castle/{castleId}/course/{courseId}/states'},
                    report:{method:'get',url:'/api/castle/v1/castle/{castleId}/user/{userId}/report/lesson/{lessonId}'},
                    unlock:{method:'put',url:'/api/castle/v1/castle/{castleId}/course/{courseId}'}
                },
                paper:{
                    getPaperList:{method:'get',url:'/api/castle/v1/room'},
                    establishPaper:{method:'post',url:'/api/castle/v1/room'},
                    getActorsList:{method:'get',url:'/api/castle/v1/room/{roomId}/actors'},
                    bespeakStart:{method:'put',url:'/api/castle/v1/room/{roomId}'},
					examState:{method:'post',url:'/api/castle/v1/room/{roomId}/start'},
					examEnd:{method:'post',url:'/api/castle/v1/room/{roomId}/done'},
					getTestPaper:{method:'get',url:'/api/lesson/v1/package/cache'}
                },
                room:{
                    list:{method:'get',url:'/api/castle/v1/room'},
                    actors:{method:'get',url:'/api/castle/v1/room/{roomId}/actors'},
                    detail:{method:'get',url:'/api/castle/v1/room/{roomId}'},
                    updateDetails:{method:'post',url:'/api/castle/v1/room/{roomId}/details'},
                    report:{
                        list:{method:'get',url:'/api/castle/v1/report'}
                    }
                }
            },
            student:{
                bonus:{
                  findByUserIds:{method:'get',url:'/api/student/v1/admin/bonus/list'}
                },
                exam:{
                    result:{method:'get',url:'/api/exam/v1/admin/exam/result', useCache:1},
                    adminExam:{method:'get', url:'/api/exam/v1/admin/exam', useCache:1},
                },
                report:{
                    moduleStatus:{method:'get',url:'api/lesson/v1/lesson/{lessonId}/module-status'},
                    general:{method:'get',url:'/api/student/v1/report/general'},
                    generalId:{method:'get',url:'/api/student/v1/report/general/{id}'},
                    episode:{method:'get',url:'/api/student/v1/admin/report/episode'}
                },
                user:{
                    pagingStudent:{method:'get',url:'/api/student/v1/student'},
                    putStudent:{method:'put',url:'/api/student/v1/student/{id}'}
                },
                grant:{
                    course:{method:'post',url:'/api/student/v1/course/grant/{courseId}'},
                    remove:{method:'delete',url:'/api/student/v1/course/grant/{courseId}'},
                    courseUpdate:{method:'post',url:'api/student/v1/course/{courseId}/update-unlock-limit'},
                    courseByUserIds:{method:'get',url:'/api/student/v1/course/lists'},
                },
                coupon:{
                		couponlist:{method:'get', url:'/api/student/v1/admin/inventory/lists'},
                		templatelist:{method:'get', url:'/api/student/v1/admin/inventory/patterns/{group}'},
                		grant:{method:'post', url:'/api/student/v1/admin/inventory/grant'},
                },
                examnation:{
                    start:{method:'get',url:'/api/lesson/v1/examnation/{id}'},
                    reportInfo:{method:'get',url:'/api/student/v1/report/episode/{reportId}', useCache:1},
                    status:{method:'get',url:'/api/student/v1/episode/admin/states'}
                },
            },
            dtq:{
                room:{
                    create:{method:'post',url:'/api/castle/v1/room'},
                    addReport:{method:'post',url:'/api/castle/v1/report'}
                }
            },
            qbank:{
                file:{
                    findByIds:{method:'get',url:'/api/qbank/file/findByIds'},
                    paging:{method:'post',url:'/api/qbank/file/paging'},
                    list:{method:'get',url:'/api/qbank/file/list'},
                    tree:{method:'get',url:'/api/qbank/file/tree'},
                    delete:{method:'post',url:'/api/qbank/file/delete'},
                    update:{method:'post',url:'/api/qbank/file/update'},
                    create:{method:'post',url:'/api/qbank/file/create'}
                },
                poem:{
                    findByIds:{method:'get',url:'/api/qbank/poem/findByIds'},
                    sortByIds:{method:'post',url:'/api/qbank/poem/sortByIds'},
                    paging:{method:'post',url:'/api/qbank/poem/paging'},
                    list:{method:'get',url:'/api/qbank/poem/list'},
                    tree:{method:'get',url:'/api/qbank/poem/tree'},
                    delete:{method:'post',url:'/api/qbank/poem/delete'},
                    update:{method:'post',url:'/api/qbank/poem/update'},
                    create:{method:'post',url:'/api/qbank/poem/create'}
                },
                ask:{
                    findByIds:{method:'get',url:'/api/qbank/ask/findByIds'},
                    sortByIds:{method:'post',url:'/api/qbank/ask/sortByIds'},
                    paging:{method:'post',url:'/api/qbank/ask/paging'},
                    list:{method:'get',url:'/api/qbank/ask/list'},
                    tree:{method:'get',url:'/api/qbank/ask/tree'},
                    delete:{method:'post',url:'/api/qbank/ask/delete'},
                    update:{method:'post',url:'/api/qbank/ask/update'},
                    create:{method:'post',url:'/api/qbank/ask/create'}
                },
                audio:{
                    findByIds:{method:'get',url:'/api/qbank/audio/findByIds'},
                    sortByIds:{method:'post',url:'/api/qbank/audio/sortByIds'},
                    paging:{method:'post',url:'/api/qbank/audio/paging'},
                    list:{method:'get',url:'/api/qbank/audio/list'},
                    tree:{method:'get',url:'/api/qbank/audio/tree'},
                    delete:{method:'post',url:'/api/qbank/audio/delete'},
                    update:{method:'post',url:'/api/qbank/audio/update'},
                    create:{method:'post',url:'/api/qbank/audio/create'}
                },
                question:{
                    get:{method:'get',url:'/api/qbank/question/get'},
                    findByIds:{method:'post',url:'/api/qbank/question/findByIds'},
                    spreadByIds:{method:'post',url:'/api/qbank/question/spreadByIds'},
                    paging:{method:'post',url:'/api/qbank/question/paging'},
                    delete:{method:'post',url:'/api/qbank/question/delete'},
                    update:{method:'post',url:'/api/qbank/question/update'},
                    create:{method:'post',url:'/api/qbank/question/create'},
                    random:{
                        knowledge:{method:'post',url:'/api/qbank/question/random/knowledge'}
                    }
                },
                word:{
                    findByIds:{method:'post',url:'/api/qbank/word/findByIds'},
                    paging:{method:'post',url:'/api/qbank/word/paging'},
                    delete:{method:'post',url:'/api/qbank/word/delete'},
                    update:{method:'post',url:'/api/qbank/word/update'},
                    create:{method:'post',url:'/api/qbank/word/create'}
                },
                picbook:{
                    findByIds:{method:'post',url:'/api/qbank/picbook/findByIds'},
                    paging:{method:'post',url:'/api/qbank/picbook/paging'},
                    delete:{method:'post',url:'/api/qbank/picbook/delete'},
                    update:{method:'post',url:'/api/qbank/picbook/update'},
                    create:{method:'post',url:'/api/qbank/picbook/create'}
                },
                pictorial:{
                    findByIds:{method:'post',url:'/api/qbank/pictorial/findByIds'},
                    paging:{method:'post',url:'/api/qbank/pictorial/paging'},
                    delete:{method:'post',url:'/api/qbank/pictorial/delete'},
                    update:{method:'post',url:'/api/qbank/pictorial/update'},
                    create:{method:'post',url:'/api/qbank/pictorial/create'}
                },
                read:{
                    findByIds:{method:'post',url:'/api/qbank/read/findByIds'},
                    paging:{method:'post',url:'/api/qbank/read/paging'},
                    delete:{method:'post',url:'/api/qbank/read/delete'},
                    update:{method:'post',url:'/api/qbank/read/update'},
                    create:{method:'post',url:'/api/qbank/read/create'}
                },
                composition:{
                    findByIds:{method:'post',url:'/api/qbank/composition/findByIds'},
                    paging:{method:'post',url:'/api/qbank/composition/paging'},
                    delete:{method:'post',url:'/api/qbank/composition/delete'},
                    update:{method:'post',url:'/api/qbank/composition/update'},
                    create:{method:'post',url:'/api/qbank/composition/create'}
                },
                video:{
                    findByIds:{method:'post',url:'/api/qbank/video/findByIds'},
                    paging:{method:'post',url:'/api/qbank/video/paging'},
                    delete:{method:'post',url:'/api/qbank/video/delete'},
                    update:{method:'post',url:'/api/qbank/video/update'},
                    create:{method:'post',url:'/api/qbank/video/create'}
                },
                article:{
                    findByIds:{method:'post',url:'/api/qbank/article/findByIds'},
                    paging:{method:'post',url:'/api/qbank/article/paging'},
                    delete:{method:'post',url:'/api/qbank/article/delete'},
                    update:{method:'post',url:'/api/qbank/article/update'},
                    create:{method:'post',url:'/api/qbank/article/create'}
                },
                qpaper:{
                    findByIds:{method:'post',url:'/api/qbank/qpaper/findByIds'},
                    detail:{method:'post',url:'/api/qbank/qpaper/detail'},
                    delete:{method:'post',url:'/api/qbank/qpaper/delete'},
                    update:{method:'post',url:'/api/qbank/qpaper/update'},
                    create:{method:'post',url:'/api/qbank/qpaper/create'},
                    getAllQPaperTypes:{method:'get',url:'/api/qbank/qpaper/getAllQPaperTypes'}
                },
                dictionary:{
                    getAllBaseSubjectList:{method:'get',url:'/api/qbank/dictionary/allBaseSubject'},
                    getAllQuestionTypeList:{method:'get',url:'/api/qbank/dictionary/allQuestionType'},
                    getAllGradeList:{method:'get',url:'/api/qbank/dictionary/allGrade'},
                    poem:{
                        paging:{method:'get',url:'/api/qbank/poem/paging'},
                        findByIds:{method:'post',url:'/api/qbank/poem/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/poem/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/poem/tree'},
                        list:{method:'get',url:'/api/qbank/poem/list'},
                        create:{method:'post',url:'/api/qbank/poem/create'},
                        delete:{method:'post',url:'/api/qbank/poem/delete'},
                        update:{method:'post',url:'/api/qbank/poem/update'}
                    },
                    ask:{
                        paging:{method:'get',url:'/api/qbank/ask/paging'},
                        findByIds:{method:'post',url:'/api/qbank/ask/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/ask/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/ask/tree'},
                        list:{method:'get',url:'/api/qbank/ask/list'},
                        create:{method:'post',url:'/api/qbank/ask/create'},
                        delete:{method:'post',url:'/api/qbank/ask/delete'},
                        update:{method:'post',url:'/api/qbank/ask/update'}
                    },
                    audio:{
                        paging:{method:'get',url:'/api/qbank/audio/paging'},
                        findByIds:{method:'post',url:'/api/qbank/audio/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/audio/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/audio/tree'},
                        list:{method:'get',url:'/api/qbank/audio/list'},
                        create:{method:'post',url:'/api/qbank/audio/create'},
                        delete:{method:'post',url:'/api/qbank/audio/delete'},
                        update:{method:'post',url:'/api/qbank/audio/update'}
                    },
                    picbook:{
                        paging:{method:'get',url:'/api/qbank/dictionary/picbook/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/picbook/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/picbook/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/picbook/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/picbook/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/picbook/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/picbook/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/picbook/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/picbook/update'}
                    },
                    pictorial:{
                        paging:{method:'post',url:'/api/qbank/dictionary/pictorial/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/pictorial/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/pictorial/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/pictorial/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/pictorial/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/pictorial/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/pictorial/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/pictorial/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/pictorial/update'}
                    },
                    read:{
                        paging:{method:'post',url:'/api/qbank/dictionary/read/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/read/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/read/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/read/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/read/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/read/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/read/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/read/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/read/update'}
                    },
                    composition:{
                        paging:{method:'post',url:'/api/qbank/dictionary/composition/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/composition/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/composition/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/composition/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/composition/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/composition/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/composition/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/composition/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/composition/update'}
                    },
                    version:{
                        paging:{method:'post',url:'/api/qbank/dictionary/version/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/version/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/version/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/version/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/version/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/version/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/version/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/version/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/version/update'}
                    },
                    book:{
                        paging:{method:'post',url:'/api/qbank/dictionary/book/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/book/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/book/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/book/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/book/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/book/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/book/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/book/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/book/update'}
                    },
                    education:{
                        paging:{method:'post',url:'/api/qbank/dictionary/education/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/education/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/education/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/education/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/education/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/education/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/education/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/education/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/education/update'}
                    },
                    knowledge:{
                        paging:{method:'post',url:'/api/qbank/dictionary/knowledge/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/knowledge/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/knowledge/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/knowledge/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/knowledge/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/knowledge/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/knowledge/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/knowledge/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/knowledge/update'}
                    },
                    concept:{
                        paging:{method:'post',url:'/api/qbank/dictionary/concept/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/concept/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/concept/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/concept/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/concept/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/concept/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/concept/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/concept/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/concept/update'}
                    },
                    skill:{
                        paging:{method:'post',url:'/api/qbank/dictionary/skill/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/skill/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/skill/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/skill/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/skill/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/skill/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/skill/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/skill/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/skill/update'}
                    },
                    measure:{
                        paging:{method:'post',url:'/api/qbank/dictionary/measure/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/measure/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/measure/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/measure/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/measure/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/measure/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/measure/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/measure/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/measure/update'}
                    },
                    mistake:{
                        paging:{method:'post',url:'/api/qbank/dictionary/mistake/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/mistake/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/mistake/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/mistake/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/mistake/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/mistake/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/mistake/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/mistake/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/mistake/update'}
                    },
                    remedy:{
                        paging:{method:'post',url:'/api/qbank/dictionary/remedy/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/remedy/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/remedy/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/remedy/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/remedy/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/remedy/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/remedy/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/remedy/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/remedy/update'}
                    },
                    subject:{
                        paging:{method:'post',url:'/api/qbank/dictionary/subject/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/subject/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/subject/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/subject/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/subject/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/subject/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/subject/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/subject/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/subject/update'}
                    },
                    tag:{
                        paging:{method:'post',url:'/api/qbank/dictionary/tag/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/tag/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/tag/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/tag/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/tag/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/tag/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/tag/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/tag/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/tag/update'}
                    },
                    question:{
                        paging:{method:'post',url:'/api/qbank/dictionary/question/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/question/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/question/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/question/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/question/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/question/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/question/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/question/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/question/update'}
                    },
                    ability:{
                        paging:{method:'post',url:'/api/qbank/dictionary/ability/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/ability/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/ability/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/ability/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/ability/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/ability/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/ability/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/ability/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/ability/update'}
                    },
                    word:{
                        paging:{method:'post',url:'/api/qbank/dictionary/word/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/word/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/word/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/word/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/word/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/word/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/word/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/word/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/word/update'}
                    },
                    video:{
                        paging:{method:'post',url:'/api/qbank/dictionary/video/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/video/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/video/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/video/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/video/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/video/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/video/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/video/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/video/update'}
                    },
                    article:{
                        paging:{method:'post',url:'/api/qbank/dictionary/article/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/article/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/article/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/article/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/article/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/article/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/article/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/article/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/article/update'}
                    },
                    qpaper:{
                        paging:{method:'post',url:'/api/qbank/dictionary/qpaper/paging'},
                        studentSearch:{method:'get',url:'/api/qbank/dictionary/qpaper/studentSearch'},
                        findByIds:{method:'post',url:'/api/qbank/dictionary/qpaper/findByIds'},
                        sortByIds:{method:'post',url:'/api/qbank/dictionary/qpaper/sortByIds'},
                        tree:{method:'get',url:'/api/qbank/dictionary/qpaper/tree'},
                        list:{method:'get',url:'/api/qbank/dictionary/qpaper/list'},
                        create:{method:'post',url:'/api/qbank/dictionary/qpaper/create'},
                        delete:{method:'post',url:'/api/qbank/dictionary/qpaper/delete'},
                        update:{method:'post',url:'/api/qbank/dictionary/qpaper/update'}
                    }
                }
            },
            foundation:{
                uncc:{
                    school:{
                        find:{method:'get',url:'/api/foundation/v1/uncc/find-school'},
                        rebuild:{method:'get',url:'/api/foundation/v1/uncc/update-school'}
                    }
                },
                imei:{
                    countLimit:{method:'post',url:'/api/foundation/v1/count-limit'},
                    checkCache:{method:'post',url:'/api/foundation/v1/imei/access-cache'},
                    checkUpStream:{method:'post',url:'/api/foundation/v1/imei/access-check-upstream'},
                    regionMemberCount:{method:'post',url:'/api/foundation/v1/scard/region-members-count'},
                    list:{method:'get',url:'/api/foundation/v1/imei'},
                    scardList:{method:'get',url:'/api/foundation/v1/scard/imei'},
                    import:{method:'post',url:'/api/foundation/v1/imei'},
                    scardImport:{method:'post',url:'/api/foundation/v1/scard/imei'},
                    renew:{method:'post',url:'/api/foundation/v1/scard/imei/renew'},
                    ban:{method:'post',url:'/api/foundation/v1/scard/imei/ban'},
                    bind:{method:'post',url:'/api/foundation/v1/imei/bind'},
                    scardBind:{method:'post',url:'/api/foundation/v1/scard/imei/bind'},
                    unbind:{method:'post',url:'/api/foundation/v1/imei/unbind'},
                    conf:{method:'get',url:'/api/foundation/v1/conf/scard'},
                    scardUnbind:{method:'post',url:'/api/foundation/v1/scard/imei/unbind'},
                    batchBind:{method:'post',url:'/api/foundation/v1/scard/imei-bind'},
                },
                esop:{
                    cancel:{method:'post',url:'/api/foundation/v1/esop/cancel'},
                    batch:{method:'post',url:'/api/foundation/v1/esop/batch'},
                },
                edition:{
                    region:{
                        list:{method:'get',url:'/api/foundation/v1/conf/editions',useCache:1},
                        update:{method:'post',url:'/api/student/v1/admin/profile/update'}
                    }
                },
                share:{
                    list:{method:'get',url:'/api/foundation/v1/share'},
                },
                conf:   {method:'post',url:'/api/foundation/v1/conf/custom/{type}/{name}'},
                url:    {method:'get',  url:'/api/foundation/v1/storage/transform'},
                ocr:    {method:'get',  url:'/api/foundation/v1/composition/english/ocr'},
                ecc:    {method:'post', url:'/api/foundation/v1/composition/english/ecc'},
                mobse:  {method:'post', url:'/api/foundation/v1/page/common/helpers/mobsec'},
                export: {method:'post',  url:'/api/foundation/v1/amazing/{key}/preset-export'},
                feedback:{method:'get', url:'/api/foundation/v1/feedback'},
                config: {method:'get',  url:'/api/foundation/v1/amazing/config'},
                search: {method:'post', url:'/api/foundation/v1/amazing/{key}'},
                downExcel:{method:'getDown', url: '/api/foundation/v1/amazing/export/{tempKey}'},
                flog:{
                    listCountByField:{method:'get',  url:'/api/foundation/v1/flog/list-count-by-field'}
                },
                region:{
                    list:{method:'get',url:'/api/foundation/v1/region'}
                }
            },
            chat:{
                record:{
                    findByDay:{method:'post',url:'/api/chat/record/findByDay'}
                }
            },
            uranus:{
                wxJsSDKConfig:{method:'post',url:'/api/uranus/v1/wechat/official/jssdk-config'},
                cos:{method:'get',url:'/api/uranus/v1/sts/{serivce}/{case}'},
                cosEnv:{method:'get',url:'/api/foundation/v1/sts/cos-secret'},
                getWXUserInfo:{method:'get',url:'/api/uranus/v1/wechat/official/user-info'}
            },
            workbook:{
                workbook:{
                    getAllTypes:{method:'get',url:'/api/course/workbook/workbook/getAllTypes'},
                    paging:{method:'post',url:'/api/course/workbook/workbook/paging'},
                    findByIds:{method:'post',url:'/api/course/workbook/workbook/findByIds'},
                    sortByIds:{method:'post',url:'/api/course/workbook/workbook/sortByIds'},
                    tree:{method:'get',url:'/api/course/workbook/workbook/tree'},
                    list:{method:'get',url:'/api/course/workbook/workbook/list'},
                    create:{method:'post',url:'/api/course/workbook/workbook/create'},
                    update:{method:'post',url:'/api/course/workbook/workbook/update'},
                    delete:{method:'post',url:'/api/course/workbook/workbook/delete'}
                },
                gallery:{
                    getAllTypes:{method:'get',url:'/api/course/workbook/gallery/getAllTypes'},
                    findByIds:{method:'post',url:'/api/course/workbook/gallery/findByIds'},
                    sortByIds:{method:'post',url:'/api/course/workbook/gallery/sortByIds'},
                    getList:{method:'get',url:'/api/course/workbook/gallery/getList'},
                    create:{method:'post',url:'/api/course/workbook/gallery/create'},
                    update:{method:'post',url:'/api/course/workbook/gallery/update'},
                    delete:{method:'post',url:'/api/course/workbook/gallery/delete'}
                }
            },
            examnation:{
                exam:{
                    refreshResourcePackage:{method:'get',url:'api/lesson/v1/package/examnation/{examId}'},
                    getAllTypes:{method:'get',url:'/api/course/examnation/exam/getAllTypes'},
                    paging:{method:'post',url:'/api/course/examnation/exam/paging'},
                    resource:{method:'post',url:'/api/course/examnation/exam/resource'},
                    findByIds:{method:'post',url:'/api/course/examnation/exam/findByIds'},
                    sortByIds:{method:'post',url:'/api/course/examnation/exam/sortByIds'},
                    tree:{method:'get',url:'/api/course/examnation/exam/tree'},
                    list:{method:'get',url:'/api/course/examnation/exam/list'},
                    create:{method:'post',url:'/api/course/examnation/exam/create'},
                    update:{method:'post',url:'/api/course/examnation/exam/update'},
                    delete:{method:'post',url:'/api/course/examnation/exam/delete'}
                },
                module:{
                    findByIds:{method:'get',url:'/api/course/examnation/module/findByIds'},//
                    sortByIds:{method:'post',url:'/api/course/examnation/module/sortByIds'},//
                    getAllModuleTypes:{method:'get',url:'/api/course/examnation/module/getAllModuleTypes'},
                    getList:{method:'get',url:'/api/course/examnation/module/getList'},
                    create:{method:'post',url:'/api/course/examnation/module/create'},
                    createDefault:{method:'post',url:'/api/course/examnation/module/createDefault'},
                    update:{method:'post',url:'/api/course/examnation/module/update'},
                    delete:{method:'post',url:'/api/course/examnation/module/delete'}
                },
                segment:{
                    findByIds:{method:'post',url:'/api/course/examnation/segment/findByIds'},
                    getAllSegmentTypes:{method:'get',url:'/api/course/examnation/segment/getAllSegmentTypes'},
                    create:{method:'post',url:'/api/course/examnation/segment/create'},
                    update:{method:'post',url:'/api/course/examnation/segment/update'},
                    delete:{method:'post',url:'/api/course/examnation/segment/delete'}
                }
            },
            exam:{
                bureau:{
                    bureau:{
                        findByIds:{method:'post',url:'/api/exam/bureau/bureau/findByIds'},
                        findByUserId:{method:'post',url:'/api/exam/bureau/bureau/findByUserId'},
                        create:{method:'post',url:'/api/exam/bureau/bureau/create'},
                        update:{method:'post',url:'/api/exam/bureau/bureau/update'},
                        delete:{method:'post',url:'/api/exam/bureau/bureau/delete'},
                        paging:{method:'post',url:'/api/exam/bureau/bureau/paging'},
                    }
                },
                analysis:{
                    castle:{
                        semester:{method:'post',url:'/api/exam/analysis/castle/semester'}
                    }
                },
                session:{
                    paging:{method:'post',url:'/api/exam/exam/session/paging'},
                    findByIds:{method:'post',url:'/api/exam/exam/session/findByIds'},
                    create:{method:'post',url:'/api/exam/exam/session/create'},
                    delete:{method:'post',url:'/api/exam/exam/session/delete'},
                    start:{method:'post',url:'/api/exam/exam/session/start'},
                    cancel:{method:'post',url:'/api/exam/exam/session/cancel'},
                    allTypes:{method:'post',url:'/api/exam/exam/session/getAllTypes'},
                },
                case:{
                    findByIds:{method:'post',url:'/api/exam/exam/case/findByIds'},
                    findBySession:{method:'post',url:'/api/exam/exam/case/findBySession'},
                },
                report:{
                    findByIds:{method:'post',url:'/api/exam/exam/report/findByIds'},
                    findBySessionIds:{method:'post',url:'/api/exam/exam/report/findBySessionIds'},
                    modify:{method:'post',url:'/api/exam/exam/report/modify'},
                    paging:{method:'post',url:'/api/exam/exam/report/paging'},
                }
            },
            course:{
                audiopackage:{
                    findByIds:{method:'post',url:'/api/course/audiopackage/package/findByIds'},
                    paging:{method:'post',url:'/api/course/audiopackage/package/paging'},
                    create:{method:'post',url:'/api/course/audiopackage/package/create'},
                    update:{method:'post',url:'/api/course/audiopackage/package/update'},
                    delete:{method:'post',url:'/api/course/audiopackage/package/delete'}
                },
                speech:{
                    analyse:{method:'post',url:'/api/course/speech/analyse'},
                },
                grant:{
                    getAllTypes:{method:'get',url:'/api/course/grant/getAllTypes'},
                    findByToId:{method:'get',url:'/api/course/grant/findByToId'},
                    create:{method:'post',url:'/api/course/grant/create'},
                    update:{method:'post',url:'/api/course/grant/update'},
                    delete:{method:'post',url:'/api/course/grant/delete'}
                },
                asset:{
                    getAllTypes:{method:'get',url:'/api/course/asset/getAllTypes'},
                    findByIds:{method:'post',url:'/api/course/asset/findByIds'},
                    sortByIds:{method:'post',url:'/api/course/asset/sortByIds'},
                    paging:{method:'post',url:'/api/course/asset/paging'},
                    tree:{method:'post',url:'/api/course/asset/tree'},
                    list:{method:'post',url:'/api/course/asset/list'},
                    create:{method:'post',url:'/api/course/asset/create'},
                    update:{method:'post',url:'/api/course/asset/update'},
                    delete:{method:'post',url:'/api/course/asset/delete'}
                },
                course:{
                    getAllTypes:{method:'get',url:'/api/course/course/getAllTypes'},
                    findByIds:{method:'post',url:'/api/course/course/findByIds'},
                    paging:{method:'post',url:'/api/course/course/paging'},
                    create:{method:'post',url:'/api/course/course/create'},
                    update:{method:'post',url:'/api/course/course/update'},
                    delete:{method:'post',url:'/api/course/course/delete'}
                },
                lesson:{
                    findByIds:{method:'get',url:'/api/course/lesson/findByIds'},
                    sortByIds:{method:'post',url:'/api/course/lesson/sortByIds'},
                    getList:{method:'get',url:'/api/course/lesson/getList'},
                    create:{method:'post',url:'/api/course/lesson/create'},
                    update:{method:'post',url:'/api/course/lesson/update'},
                    delete:{method:'post',url:'/api/course/lesson/delete'},
                },
                module:{
                    findByIds:{method:'get',url:'/api/course/module/findByIds'},//
                    sortByIds:{method:'post',url:'/api/course/module/sortByIds'},//
                    getAllModuleTypes:{method:'get',url:'/api/course/module/getAllModuleTypes'},
                    getList:{method:'get',url:'/api/course/module/getList'},
                    create:{method:'post',url:'/api/course/module/create'},
                    createDefault:{method:'post',url:'/api/course/module/createDefault'},
                    update:{method:'post',url:'/api/course/module/update'},
                    delete:{method:'post',url:'/api/course/module/delete'}
                },
                segment:{
                    findByIds:{method:'post',url:'/api/course/segment/findByIds'},
                    getAllSegmentTypes:{method:'get',url:'/api/course/segment/getAllSegmentTypes'},
                    create:{method:'post',url:'/api/course/segment/create'},
                    update:{method:'post',url:'/api/course/segment/update'},
                    delete:{method:'post',url:'/api/course/segment/delete'}
                },
                graph:{//,localHost:'http://127.0.0.1:7010','x_lx_auth':1
                    getAllGraphTemplates:{method:'get',url:'/api/course/graph/getAllGraphTemplates'},
                    sortByIds:{method:'post',url:'/api/course/graph/sortByIds'},
                    getList:{method:'post',url:'/api/course/graph/getList'},
                    tree:{method:'post',url:'/api/course/graph/tree'},
                    list:{method:'post',url:'/api/course/graph/list'},
                    create:{method:'post',url:'/api/course/graph/create'},
                    update:{method:'post',url:'/api/course/graph/update'},
                    delete:{method:'post',url:'/api/course/graph/delete'}
                },
            }
        }

    },
    oppia:{
        host:'https://jiaohu.lianxue.com/',
        getCollection:'/collection_handler/data/'
    }
};
export default conf;
